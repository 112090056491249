export const BFM_URL = "https://api.boty.shoes";

export const SUCCESS = "УСПЕХ!";

export const INPUT_MOVIE = "Нужно ввести ключевое слово";
export const DATA_ERROR =
  "Во время запроса произошла ошибка. Возможно, проблема с соединением или сервер недоступен. Подождите немного и попробуйте ещё раз";
export const ERROR_LOGIN_TEXT = "Неправильный email или пароль";
export const ERROR_PROFILE_EMAIL = "Такой email уже существует";
export const ERROR_REGISTER_TEXT = "Что-то пошло не так! Попробуйте ещё раз.";
export const NOTHING_FOUND = "Ничего не найдено";
export const WIDTH1280 = 1279;
export const WIDTH768 = 767;
export const WIDTH320 = 319;
export const WIDTH480 = 481;
export const CARDS_FOR_WIDTH_1280 = 12;
export const CARDS_FOR_WIDTH_768 = 8;
export const CARDS_FOR_WIDTH_320 = 5;
export const ADD_CARDS_FOR_WIDTH_1280 = 3;
export const ADD_CARDS_FOR_WIDTH_320 = 2;
export const SHORT_FILM = 40;
export const Error_401 = "Error: 401";

export const ABOUT_CONTENT_SUBTITLE_WELCOME = "ДОБРО ПОЖАЛОВАТЬ В Boty shoes";
export const ABOUT_CONTENT_TEXT_WELCOME =
  "Мы отбираем Наши коллекции обуви с особой любовью и трепетом";
export const ABOUT_CONTENT_TEXT_WELCOME_TWO =
  "В каждой коллекции всегда есть как эффектные трендовые решения, так и базовые варианты на каждый день. Удобная колодка, безупречное качество и актуальный дизайн – неотъемлемые составляющие обуви и аксессуаров от «Boty shoes».";
export const ABOUT_CONTENT_SUBTITLE_BE = "БУДЬ СТИЛЬНОЙ! БУДЬ СОБОЙ!";
export const ABOUT_CONTENT_TEXT_BE =
  "Мы постоянно стремимся повышать уровень своего сервиса, который основывается на традициях гостеприимства и радушия и отличается персональным подходом к каждому клиенту.";
export const ABOUT_CONTENT_TEXT_BE_TWO =
  "В нашем пространстве заложена идея места встречи подруг, любительниц моды, стиля, и конечно, обуви и аксессуаров. Это место встречи, где можно вместе искать вдохновение, делиться своими новостями, радоваться жизни. Это место, где мы можем быть стильными и, при этом, всегда оставаться собой.";

export const DELIVERY_CONTENT_SUBTITLE =
  "Доставка заказов осуществляется по всей России, а так же странам ближнего зарубежья";
export const DELIVERY_TERMS = "Условия доставки по Екатеринбургу:";
export const DELIVERY_WITH_FITTING =
  "Доставка по Екатеринбургу осуществляется Яндекс курьером.";
export const DELIVERY_DATE_AND_TIME =
  " Стоимость доставки зависит от адреса и времени🙏";
// export const DELIVERY_PAYMENT = "Оплата заказа происходит после примерки.";
// export const INFORMATION_ABOUT_HOW_TO_PAY_FOR_DELIVERY =
//   "Информация о способах оплаты: оплата товара производится в наличной или безналичной форме (онлайн-перевод на банковские карты Сбербанк,Тинькофф).";
// export const DELIVERY_LIMITATION =
//   "На доставку вы можете заказать до 5 пар обуви.";
// export const COURIER_TIME =
//   "Время нахождения курьера по адресу клиента ограничено 20 минутами.";
// export const COURIER_DELIVERY =
//   "Доставка по Екатеринбургу осуществляется курьером бесплатно - при выкупе от 1 пары (кроме моделей по специальным предложениям и акциям).";
export const TERMS_OF_DELIVERY_IN_RUSSIA = "Условия доставки по России:";
export const DELIVERY_SERVICE =
  "Мы работаем с курьерской службой СДЭК и почтой России.";
export const EXAMPLE =
  "Отправка заказа производится в течении 2-х дней с момента заказа (более ранние сроки отправки согласовываются индивидуально с менеджером).";
export const ORDER_STATUS =
  "Стоимость и срок доставки зависит от региона доставки и рассчитывается менеджером во время оформлении заказа (обычно стоимость не превышает суммы 300-500₽).";
export const DISPATCH_OF_ORDER = "Оплата заказа происходит после оформления.";
export const COST_AND_TIME =
  "Отследить статус заказа вы сможете по трек номеру посылки, направленного вам в сообщении, после передачи посылки на доставку.";
// export const ORDER_PAYMENT = "Оплата заказа происходит после оформления.";
export const PAYMENT_INFORMATION =
  "Также стоит учитывать, что в период старта SALE, праздничных дней и выходных время сборки и отправки заказа может быть увеличено.";
// export const SALE_SHIPPING =
// "Также стоит учитывать, что в период старта SALE, праздничных дней и выходных время сборки и отправки заказа может быть увеличено.";

export const MAP_CITY =
  "https://yandex.ru/maps/org/bum/1037659140/?utm_medium=mapframe&utm_source=maps";
export const MAP_STREET =
  "https://yandex.ru/maps/54/yekaterinburg/category/shopping_mall/184108083/?utm_medium=mapframe&utm_source=maps";
export const MAP_POINT =
  "https://yandex.ru/map-widget/v1/?ll=60.596520%2C56.831733&mode=search&oid=1037659140&ol=biz&sctx=ZAAAAAgBEAAaKAoSCZjArbt5TE5AERlYx%2FFDa0xAEhIJ%2BWcG8YEd1z8Rpgwc0NIVvD8iBgABAgMEBSgKOABA3ZwGSAFqAnJ1nQHNzMw9oAEAqAEAvQFfm51nwgEFhNjl7gOCAgvRgtGGINCx0YPQvIoCAJICAJoCDGRlc2t0b3AtbWFwcw%3D%3D&sll=60.596520%2C56.831733&sspn=0.020204%2C0.006138&text=%D1%82%D1%86%20%D0%B1%D1%83%D0%BC&utm_source=share&z=16.16";
export const CONTACTS_CITY = "Бум";
export const CONTACTS_STREET = "Торговый центр в Екатеринбурге";

export const SHOECARE_TEXT = "Уход за обувью";
export const SHOECARE_SMOOTHSKIN = "Гладкая кожа";
export const SHOECARE_SMOOTHSKIN_TEXT =
  "Гладкая кожа — самая популярная. Обычно имеет характерный блеск или полуглянец. Лучшей считается телячья кожа. Она мягкая, хорошо взаимодействует с питательными веществами, содержащимися в кремах, и довольно легко восстанавливается. Со временем приобретает благородную патину и при регулярном уходе служит своим владельцам десятилетиями, с каждым годом увеличивая свою ценность.";
export const SHOECARE_CLEAN = "Чистка";
export const SHOECARE_CLEAN_TEXT =
  "Щёткой смахните с обуви пыль и засохшую грязь. Также перед тем, как нанеси крем или воск, нужно тщательно очистить обувь от остатков косметики, нанесённой раньше. Большая щётка из конского волоса — то что надо для этой работы.";
export const SHOECARE_CLEANING_POWER_RECOVERY =
  "Очистка, питание и восстановление";
export const SHOECARE_CLEANING_POWER_RECOVERY_TEXT =
  "На этом этапе используем комплексное средство, которое решит сразу несколько задач. Бальзам проникает в структуру кожи, глубоко очищая, питая и восстанавливая. Он очистит и освободит поры кожи, подготовив её к впитыванию крема, воска и содержащихся в них пигментов.";
export const SHOECARE_CLEANING_POWER_RECOVERY_TEXT2TH =
  "Если кожа очень грязная или нанесенные раньше средства и воски трудно удалить кремом, используйте более сильный очиститель.";
export const SHOECARE_POWER_RESTORATION_COLOR =
  "Питание и восстановление цвета.";
export const SHOECARE_POWER_RESTORATION_COLOR_TEXT =
  "Если кожа выцвела или изменила цвет, на ней появились мелкие царапины, следы износа или ее внешний вид просто оставляет желать лучшего, зарядите её дозой питательных веществ. Пигменты и воски вернут глубокий насыщенный цвет и достойный вид.";
export const SHOECARE_POWER_RESTORATION_COLOR_TEXT2TH =
  "Чтобы придать ещё больше блеска, полировку можно повторить. Но по-настоящему яркого блеска одним кремом не добиться. Максимум — естественного полуглянца.";

export const SHOECARE_GLASSAGE = "Глассаж или полировка до блеска";
export const SHOECARE_GLASSAGE_TEXT =
  "Чтобы блистать в любой компании, отполируйте обувь воском. С ними вы сможете добиться настоящего зеркального блеска. Поначалу будет непросто, но со временем вы нащупаете свою технику.";
export const SHOECARE_GLASSAGE_TEXT_PART2 =
  "Помните, что не каждую кожу можно отполировать до действительно зеркального состояния. Будьте уверены: ваша обувь в любом случае выглядит блестяще после такой полировки!";
export const SHOECARE_GLASSAGE_TEXT_PART3 =
  "Наносите воск понемногу салфеткой. Чтобы добиться максимального сияния, капните чуть воды — но только в самом конце! Пока первые слои воска не затвердеют, использовать воду нельзя. На кожу она попадать не должна, её втирают только в уже застывший воск.";
export const SHOECARE_GLASSAGE_TEXT_PART4 =
  "Сияющий глянец — яркий эффект, подходящий как для официальных, так и неформальных ситуаций. Но есть обувь, которая от этого эффекта не выигрывает. Поэтому, прежде чем приступить к полировке, хорошенько взвесьте «за» и «против».";
export const SHOECARE_GLASSAGE_TEXT_PART5 =
  "Обратите внимание на то, что воски для глассажа не заменяют воски для базового ухода. Их используют только на завершающем этапе полировки и только для зеркального блеска.";

export const SHOECARE_SUEDE_NUBUCK = "Замша и нубук";
export const SHOECARE_SUEDE_NUBUCK_TEXT =
  "Замша и нубук имеют одинаковую бархатистую фактуру, но похожи только на первый взгляд. Если упростить, замша — это внутренняя поверхность шкуры, а нубук — отшлифованная внешняя. Ворс у замши чуть длиннее, чем у нубука. Ухаживают за обеими кожами одинаково. Вопреки внешнему виду, эти материалы прочны, устойчивы к загрязнениям и просты в уходе.";
export const SHOECARE_SUEDE_NUBUCK_TEXT_PART2 =
  "Для базового ухода за ворсовыми кожами не требуется много средств.";
export const SHOECARE_SUEDE_NUBUCK_TEXT_PART3 =
  "Сухая чистка. Почистить от пыли и сухой грязи можно и обычной обувной щеткой. Но специальные аксессуары и средства эффективнее, поэтому их просто необходимо иметь в домашнем арсенале.";
export const SHOECARE_SUEDE_NUBUCK_TEXT_PART4 =
  "Незаметное, но очень эффективное средство для чистки нубука и замши — специальный ластик. Он немного напоминает пемзу.";
export const SHOECARE_MOISTURE_PROTECTION = "Защита от влаги и грязи.";
export const SHOECARE_MOISTURE_PROTECTION_TEXT =
  "Чтобы избежать серьезных загрязнений в будущем или хотя бы облегчить чистку, обязательно защищаем замшу и нубук водотталкивающей пропиткой. Эффект от такой защиты ошеломляет: капли воды буквально стекают по поверхности! Но не думайте, что наша обувь теперь не хуже резиновых сапог! За ней все равно нужно ухаживать и не мочить лишний раз. Даже защита не справится, если разгуливать по глубоким лужам или по колено в снегу.";
export const SHOECARE_MOISTURE_PROTECTION_TEXT2TH =
  "Обновлять защиту нужно в зависимости от материала, частоты и условий носки. Может быть, через 2–3 недели, может, через 2–3 месяца. Здесь нет жестких рамок. Ориентируйтесь по ситуации.";

export const SHOECARE_PATENT_LEATHER = "Лакированная кожа";
export const SHOECARE_PATENT_LEATHER_TEXT =
  "Лакированная кожа блестящая и гладкая. Модная лакированная обувь обычно изготавливается из кожи с полиуретановым или акриловым покрытием. Лакированные элементы также используются при производстве сумок, аксессуаров и спортивной обуви, например, Air Jordan.";
export const SHOECARE_PATENT_LEATHER_TEXT2TH =
  "Этот вид кожи не нуждается в смазке, так как не впитывает питательные вещества, её поры закрыты. Обычные средства по уходу могут её повредить. Лакированная кожа довольно прочная, хотя имеет тенденцию трескаться в местах наибольшей нагрузки. Рекомендуем специальные средства для ухода и восстановления.";
export const SHOECARE_PATENT_LEATHER_CLEANING = "Чистка";
export const SHOECARE_PATENT_LEATHER_CLEANING_TEXT =
  "Удалите пыль и грязь щеткой из конского волоса.";
export const SHOECARE_PATENT_LEATHER_RESTORATION = "Чистка и восстановление";
export const SHOECARE_PATENT_LEATHER_RESTORATION_TEXT =
  "Для чёрной кожи используйте чёрный крем, для цветных — бесцветный.";
export const SHOECARE_PATENT_LEATHER_RESTORATION_TEXT2TH =
  "Наносите понемногу тканевой салфеткой.";
export const SHOECARE_PATENT_LEATHER_POLISHING = "Полировка";
export const SHOECARE_PATENT_LEATHER_POLISHING_TEXT =
  "Дайте высохнуть (3–5 минут) и тщательно отполируйте поверхность мягкой хлопковой тканью до блеска.";
export const SHOECARE_PATENT_LEATHER_POLISHING_TEXT2TH =
  "Помните, что хранить обувь нужно на хороших деревянных колодках. Они предотвращают заломы и сохраняют форму верха. Для лакированных туфель это особенно важно.";

export const SHOECARE_NUBUCK = "Нубук";
export const SHOECARE_NUBUCK_TEXT =
  "Нубук проходит дубление специальными жирами и маслами. Отличается повышенной износостойкостью, характерной плотностью и внешним видом. Чаще всего из него делают походную, трекинговую и парусную обувь.";
export const SHOECARE_DRY_CLEANING = "Сухая чистка";
export const SHOECARE_DRY_CLEANING_TEXT =
  "Удалите пыль и грязь щеткой из натуральной щетины.";
export const SHOECARE_WET_CLEANING = "Влажная чистка";
export const SHOECARE_WET_CLEANING_TEXT =
  "Для стойких загрязнений и пятен мы использовали сильнодействующее мыло с восстанавливающим эффектом. Используйте как можно меньше воды. Перед использованием обязательно протестируйте средство на незаметном участке.";
export const SHOECARE_POWER = "Питание";
export const SHOECARE_POWER_TEXT =
  "Чтобы восстановить поверхность, используйте специальный жир. Качественный жир очень эффективен. Наносите его хлопковой салфеткой понемногу.";
export const SHOECARE_POLISHING = "Полировка";
export const SHOECARE_POLISHING_TEXT =
  "После того, как жир полностью впитался и обувь высохла, можно отполировать её щеткой.";
export const SHOECARE_POLISHING_TEXT_PART2 =
  "Ухаживая за светлой кожей, учитывайте, что жиры могут делать её темнее — локально или по всей поверхности.";
export const SHOECARE_POLISHING_TEXT_PART3 =
  "Эффект потемнения многим нравится.";

export const SHOECARE_ECO_COAT = "Эко-кожа";
export const SHOECARE_ECO_COAT_TEXT =
  "Экокожа обладает не только хорошим внешним видом, но и отличается высокой износостойкостью и долговечностью. Конечно, при условии правильного ухода за этим материалом. А позаботиться о нем несложно. Важно запомнить основные рекомендации специалистов, как ухаживать за вещами из экокожи в домашних условиях.";
export const SHOECARE_ECO_COAT_CLEAN = "Чистка";
export const SHOECARE_ECO_COAT_CLEAN_TEXT =
  "Используйте мягкие салфетки. Для устранения пыли или легких загрязнений рекомендуется использовать мягкие тряпки из бязи, фланели, софткоттона либо микрофибры. При этом салфетки необходимо намочить водой и тщательно отжать. Экокожа совершенно не любит чрезмерного увлажнения.";
export const SHOECARE_ECO_COAT_CLEAN_TEXT2TH =
  "Противопоказано применение скребков, жестких губок, щеток. После сильного и агрессивного воздействия на экокоже могут остаться потертости, трещины или царапины, устранить которые невозможно.";
export const SHOECARE_ECO_COAT_PROTECTION = "Защита";
export const SHOECARE_ECO_COAT_PROTECTION_TEXT =
  "Протирайте водоотталкивающим средством. Эту процедуру желательно проводить раз в полгода.";
export const SHOECARE_ECO_COAT_CARING = "Уход";
export const SHOECARE_ECO_COAT_CARING_TEXT =
  "Применяйте уходовые средства. Чтобы обеспечить экокоже необходимый блеск, и тем самым создать полную иллюзию натуральности, рекомендуется использовать те же кремы и спреи, которые применяются для настоящей кожи. Но при этом важно учесть такой момент. Искусственный материал не способен впитывать крем. Поэтому через время необходимо протереть поверхность мягкой салфеткой для устранения излишка средства.";
