import React from "react";
import {
  COST_AND_TIME,
  COURIER_DELIVERY,
  COURIER_TIME,
  DELIVERY_CONTENT_SUBTITLE,
  DELIVERY_DATE_AND_TIME,
  DELIVERY_LIMITATION,
  DELIVERY_PAYMENT,
  DELIVERY_SERVICE,
  DELIVERY_TERMS,
  DELIVERY_WITH_FITTING,
  DISPATCH_OF_ORDER,
  EXAMPLE,
  INFORMATION_ABOUT_HOW_TO_PAY_FOR_DELIVERY,
  ORDER_PAYMENT,
  ORDER_STATUS,
  PAYMENT_INFORMATION,
  SALE_SHIPPING,
  TERMS_OF_DELIVERY_IN_RUSSIA,
} from "../../utils/constants";

export default function Delivery() {
  return (
    <main className="content">
      {/*<h1 className="content__title">Доставка</h1>*/}
      <h2 className="content__subtitle">{DELIVERY_CONTENT_SUBTITLE}</h2>
      <section className="shipping">
        <article className="shipping__city">
          <div className="shipping__info">
            <h3 className="content__subheading">{DELIVERY_TERMS}</h3>
            <ul>
              <li className="content__text">{DELIVERY_WITH_FITTING}</li>
              <li className="content__text">{DELIVERY_DATE_AND_TIME}</li>
              {/* <li className="content__text">{DELIVERY_PAYMENT}</li>
							<li className="content__text">{INFORMATION_ABOUT_HOW_TO_PAY_FOR_DELIVERY}</li>
							<li className="content__text">{DELIVERY_LIMITATION}</li>
							<li className="content__text">{COURIER_TIME}</li>
							<li className="content__text">{COURIER_DELIVERY}</li> */}
            </ul>
          </div>
          <img
            src={require("../../images/dos-ekb.jpg")}
            alt="Доставка по Екатеринбургу"
            className="content__img"
          />
        </article>

        <article className="shipping__country">
          <img
            src={require("../../images/dos-rus.jpg")}
            alt="Доставка по России"
            className="content__img"
          />
          <div className="shipping__info">
            <h3 className="content__subheading">
              {TERMS_OF_DELIVERY_IN_RUSSIA}
            </h3>
            <ul>
              <li className="content__text">{DELIVERY_SERVICE}</li>
              <li className="content__text">{EXAMPLE}</li>
              <li className="content__text">{ORDER_STATUS}</li>
              <li className="content__text">{DISPATCH_OF_ORDER}</li>
              <li className="content__text">{COST_AND_TIME}</li>
              {/* <li className="content__text">{ORDER_PAYMENT}</li> */}
              <li className="content__text">{PAYMENT_INFORMATION}</li>
            </ul>
          </div>
        </article>

        {/* <h4 className="shipping__sale">{SALE_SHIPPING}</h4> */}
      </section>
    </main>
  );
}
