import React, { useState } from "react";
import { useParams } from "react-router-dom";

export default function Product({ cards, setCards, products, preloader }) {
  const { productId } = useParams();
  const [selectedSize, setSelectedSize] = useState(null);
  const selectedProduct = products.find((product) => product.id === productId);

  const handleSizeClick = (size) => {
    setSelectedSize(size);
  };

  const [selectedSlide, setSelectedSlide] = useState(1);
  const slideCount = 6;

  const handleImageClick = () => {
    const nextSlide = selectedSlide % slideCount === 0 ? 1 : selectedSlide + 1;
    setSelectedSlide(nextSlide);
  };

  const allSizes = ["35", "36", "37", "38", "39", "40", "41"];

  // Проверка на пустой массив sizes
  const isSizesEmpty = selectedProduct.sizes.length === 0;

  return (
    <main className="content">
      <div className="product">
        <div className="product__slider product__middle">
          <div className="product__slides">
            {Array.from({ length: slideCount }).map((_, slideNumber) => (
              <input
                key={slideNumber}
                type="radio"
                name="r"
                id={`r${slideNumber + 1}`}
                value={slideNumber + 1}
                checked={selectedSlide === slideNumber + 1}
                onChange={() => setSelectedSlide(slideNumber + 1)}
              />
            ))}

            {Array.from({ length: slideCount }).map((_, slideNumber) => (
              <div
                key={slideNumber}
                className={`product__slide s${slideNumber + 1}`}
              >
                <img
                  src={selectedProduct[`img${slideNumber + 1}`]}
                  alt={selectedProduct.name}
                  className="product__img"
                  onClick={handleImageClick}
                />
              </div>
            ))}
          </div>
          <div className="product__navigation">
            {[...Array(slideCount).keys()].map((slideNumber) => (
              <label
                key={slideNumber}
                htmlFor={`r${slideNumber + 1}`}
                className={`product__bar ${
                  selectedSlide === slideNumber + 1 ? "selected" : ""
                }`}
              ></label>
            ))}
          </div>
        </div>

        <div className="product__title">
          <h1 className="product__name">{selectedProduct.name}</h1>
          <div className="product__price">
            <h4 className="product__price-old">{selectedProduct.priceOld}</h4>
            <h4 className="product__price-new">{selectedProduct.priceNew}</h4>
          </div>
          <p
            className={
              selectedProduct.category === "shoes" ? "product__caption" : ""
            }
          >
            {selectedProduct.category === "shoes" ? "Размеры" : ""}
          </p>

          <div className="product__sizes">
            {selectedProduct.category === "shoes" &&
              allSizes.map((size) => {
                // Проверяем, доступен ли размер
                if (selectedProduct.sizes.includes(size)) {
                  return (
                    <p
                      key={size}
                      className={`product__size ${
                        selectedSize === size ? "selected" : ""
                      }`}
                      onClick={() => handleSizeClick(size)}
                    >
                      {size}
                    </p>
                  );
                }
                return null; // Если размер недоступен, не рендерим его
              })}
          </div>

          <p className="product__caption">Цвет</p>

          <div className="product__colors">
            {selectedProduct.color.map((colorClass) => (
              <button
                key={colorClass.name}
                className={`product__color ${colorClass.class} ${
                  !selectedProduct.color.some(
                    (color) => color.name === colorClass.name
                  )
                    ? "unavailable-color"
                    : ""
                }`}
              >
                <span className="product__color_text">
                  {colorClass ? colorClass.name : ""}
                </span>
              </button>
            ))}
          </div>

          <p className="product__caption">Материал</p>
          <p className="product__descriptiontext">{selectedProduct.material}</p>

          <form
            className="product__form"
            action={isSizesEmpty ? "/product/78" : "https://wa.me/79956776572"}
            method="get"
            target="_blank"
          >
            <button className="product__contact" type="submit">
              {isSizesEmpty ? "Похожие товары" : "Уточнить наличие"}
            </button>
          </form>

          <p className="product__caption">Описание</p>
          <p className="product__descriptiontext">
            {selectedProduct.description}
          </p>

          <p className="product__caption">Страна</p>
          <p className="product__descriptiontext">{selectedProduct.country}</p>
        </div>
      </div>
    </main>
  );
}
